

import { VueEditor } from "vue2-editor";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
    components: {
        VueEditor, VuePdfApp
    },
    data() {
        return {

        form : {
            id : '',
            judul : '',
            isi : '',
            file : null,
            file_old : '',
            file_type : '',
            unit_kerja : '',
            status : '',
            keterangan : '',
        },
        
        list_data : [],
        loadPDF : false,

        page_first: 1,
        page_last: 0,
        cari_value: "",
        cek_load_data : true,


        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        mdl_lihat : false,
        btn_add: false,
        }
    },
    methods: {


        getView : function(){
            this.$store.commit("shoWLoading");
            fetch(this.$store.state.url.URL_presensi_pengumuman + "view", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    data_ke: this.page_first,
                    cari_value: this.cari_value
                })
            })
                .then(res => res.json())
                .then(res_data => {
                    this.list_data = res_data.data;
                    this.page_last = res_data.jml_data;
                    this.$store.commit("hideLoading");
                    console.log(res_data);
            });
        },


        addData : function(number) {
            var formData = new FormData();


            formData.append('id', this.form.id);
            formData.append('judul', this.form.judul);
            formData.append('isi', this.form.isi);
            formData.append('keterangan', this.form.keterangan);

            formData.append('file', this.form.file);
            formData.append('file_old', this.form.file_old);
            formData.append('unit_kerja', this.form.unit_kerja);
            

            fetch(this.$store.state.url.URL_presensi_pengumuman + "addData", {
                method: "POST",
                headers: {
                    // "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: formData
            }).then(res_data => {
                this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
                this.getView();
            });
            // console.log(this.form.jeniskategorilokasi)
        },



        editData : function(){
            var formData = new FormData();

            formData.append('id', this.form.id);
            formData.append('judul', this.form.judul);
            formData.append('isi', this.form.isi);
            formData.append('keterangan', this.form.keterangan);

            formData.append('file', this.form.file);
            formData.append('file_old', this.form.file_old);
            formData.append('unit_kerja', this.form.unit_kerja);


            fetch(this.$store.state.url.URL_presensi_pengumuman + "editData", {
                method: "POST",
                headers: {
                    // "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: formData
            }).then(res_data => {
                this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
                this.getView();
            });
        },

        removeData : function(E){
            fetch(this.$store.state.url.URL_presensi_pengumuman + "removeData", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({id : this.form.id})
            }).then(res_data => {
                this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
                this.getView();
            });

        },
        selectData : function(data){
            console.log(data)
            this.form.id = data.id;
            this.form.judul = data.judul;
            this.form.isi = data.isi;

            this.form.file_old = data.file;
            this.form.file_type = data.file_type;
            this.form.keterangan = data.keterangan;

    
        },

        // ====================================== PAGINATE ====================================
            Notify : function(message, positive, icon){
                this.$q.notify({
                    message: message,
                    color: positive,
                    icon: icon,
                    position : 'top',
                    timeout: 500,
                })
            },
            btn_prev : function(){
                this.cek_load_data = true;
                if(this.page_first>1){
                    this.page_first--
                }else{
                    this.page_first = 1;
                }
                this.getView();
            },

            btn_next : function(){
                if(this.page_first >= this.page_last){
                    this.page_first == this.page_last
                }else{
                    this.page_first++;
                }
                this.getView();
            },

            cari_data : function(){
                this.page_first = 1;
                this.getView();
            },


        // ====================================== PAGINATE ====================================







    },

    mounted () {
        this.form.unit_kerja = this.$store.state.unit_kerja
        console.log(this.form.unit_kerja)
        this.getView();
    },
}
